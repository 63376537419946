import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import BrandContainer from "../containers/global/brand/index";
import FunFactContainer from "../containers/global/funfact";
import NewsletterArea from "../containers/global/newsletter";
import PageBanner from "../containers/global/page-banner";
import TeamContainer from "../containers/global/team";
import TestimonialReverse from "../containers/global/testimonial-reverse";
import ServiceListContainer from "../containers/service/service-list";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import Layout from "../layouts/index";

const PortfolioPage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Gujju Infotech - Service" />
                <div className="wrapper">
                    <Header />
                    <PageBanner
                        title="Portfolio"
                        excerpt="<h2>coming soon....</h2>"
                        image="./images/service/Website_Creator.svg"
                    />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default PortfolioPage;
